import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import NewsCard from '../components/NewsCard/NewsCard'
import Layout from '../fragments/layout/Layout'
import useSeoQuery from "../queries/useSeoQuery"
import Seo from "../components/seo"

const useNews = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulReportaje(sort: { fields: order, order: ASC }) {
        edges {
          node {
            mes
            medio
            ao(formatString: "YYYY")
            portada {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
            slugUrl
          }
        }
      }
    }
  `)

  return data
}

const Reportajes = () => {
  const data = useNews()
  const seo = useSeoQuery()
  return (
    <Layout>
    <Seo
        title="Reportajes"
        lang="es"
        titleSEO={seo.reportajesTitleSeo}
        description={seo.reportajesDescriptionSeo.reportajesDescriptionSeo}
      />
      <div className="marginTopFather"></div>
      <div className="container">
        <div className="row">
        {
          data.allContentfulReportaje.edges?.map((news, i) => (
          <div  
            className="col-lg-4 col-md-6 mb-5 px-5 pb-5 justify-content-center"
            key={i}
          >
            <NewsCard 
              image={news.node.portada.gatsbyImageData} 
              media={news.node.medio} 
              month={news.node.mes} 
              year={news.node.ao}
              slug={news.node.slugUrl}
            />
          </div>
          ))
        } 
        </div>
      </div>
    </Layout>
  )
}

export default Reportajes
