import React from 'react'
import { navigate, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import './NewsCard.scss'

const NewsCard = ({ image, media, month, year, slug }) => {

  const goToNews = () => {
    navigate(`/reportajes/${slug}`)
  }

  return (
    <>
    <div className="row justify-content-center mb-2 container-img">
      <GatsbyImage
        image ={getImage(image)}
        onClick={goToNews}
        className="img-card"
        alt=""
      />
    </div>
    <div className="row info-card-product-mini">
      <Link to={`reportajes/${slug}`} className="news-info"><b>{media}</b> <br/> {month} {year} </Link>
    </div>
    </>
  )
}

export default NewsCard
